import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as AlertProvider } from 'react-alert';

// import * as serviceWorker from './common/serviceWorker';
import App from './App';

// COMPONENTS
import AlertTemplate from 'components/AlertTemplate';

ReactDOM.createRoot(document.getElementById("app")).render(
  <AlertProvider template={AlertTemplate} position="bottom right" timeout={30000} containerStyle={{ zIndex: '1002' }}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AlertProvider>
);

// serviceWorker.unregister();
