import React, { lazy, useCallback, useEffect, useState, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import SmoothScroll from 'smooth-scroll';
// import CookieConsent from 'react-cookie-consent';
// import GoogleAnalytics from 'react-ga';
// import FacebookPixel from 'react-facebook-pixel';

// COMPONENTS
import Loader from 'components/Loader';
import Topbar from 'components/Topbar';
import Footer from 'components/Footer';

// STYLE
import 'theme/base.scss';

// PAGES
// const LoginPage = lazy(() => import('pages/login'));
// const RegistrationPage = lazy(() => import('pages/registration'));
const IndexPage = lazy(() => import('pages/index'));
const ImpressumPage = lazy(() => import('pages/impressum'));
// const NotFoundPage = lazy(() => import('pages/404'));

// CONSTANTS
const smoothScroll = new SmoothScroll('a[href*="#"]', {
  speed: 300,
  speedAsDuration: true,
  easing: 'easeInOutCubic',
  updateURL: false,
  offset: 64 // topbar height
});

export default function App() {
  // const [ cookieConsent, setCookieConsent ] = useState(cookie.get(COOKIE_CONSENT_KEY) === 'true');

  // GOOGLE ANALYTICS
  // useEffect(() => {
  //   if (cookieConsent) {
  //     //--> Google Analytics
  //     GoogleAnalytics.initialize('UA-63432453-4', {
  //       titleCase: false,
  //       // debug: true
  //     });
  //     GoogleAnalytics.set({ anonymizeIp: true });
  //     //--> Facebook Pixel
  //     FacebookPixel.init('1047956452226632', null, {
  //       autoConfig: true,
  //       debug: true
  //     });
  //   }
  // }, [cookieConsent]);

  // SMOOTH SCROLL (on load & history listener)
  const navigate = useNavigate();
  const location = useLocation()
  const [ mPathname, setPathname ] = useState(location ? location.pathname : '/');
  const handleHashScroll = useCallback((loc) => {
    const { hash, pathname } = loc;
    if (hash && hash !== '') {
      setTimeout(() => {
        const scrollEl = document.getElementById(hash.split('#')[1]);
        if (scrollEl) smoothScroll.animateScroll(scrollEl);
      }, 500);

      navigate(pathname, { replace: true }); // clear url
    }
  }, []);
  useEffect(() => {
    // handleHashScroll(location);
    setPathname(location ? location.pathname : '/'); // state var for topbar
    if (location && location.hash.trim().length < 1) window.scrollTo(0, 0);
    else handleHashScroll(location);


    // if (cookieConsent) {
      // // --> Google Analytics
      // GoogleAnalytics.set({ page: location.pathname });
      // GoogleAnalytics.pageview(location.pathname);
      // //--> Facebook Pixel
      // FacebookPixel.pageView();
    // }
  }, [location, handleHashScroll/*, cookieConsent*/]);

  // CUSTOM ROUTE
  // const Route = useCallback(({ exact, path, redirect, redirectTo, component: PageComponent, ...props }) => {
  //   if (redirect === true) return (<Navigate exact={exact} to={redirectTo} />);

  //   return <RouterRoute exact={exact} path={path} render={routerProps => <Suspense fallback={<Loader size={64} vcenter />}>
  //     <PageComponent {...routerProps} {...props} />
  //   </Suspense>} />;
  // }, []);

  return (
    <>
        <Topbar loose={mPathname === '/'} />

        <main>
          <Routes>
            <Route exact path="/" element={<Suspense fallback={<Loader size={64} vcenter />}><IndexPage /></Suspense>} />
            <Route exact path="/impressum" element={<Suspense fallback={<Loader size={64} vcenter />}><ImpressumPage /></Suspense>} />

            {/* ERROR PAGES */}
            {/* <Route component={NotFoundPage} /> */}
          </Routes>

          <Footer />
        </main>

        {/* <CookieConsent
          buttonText={<>{ strings.cookie_banner.accept } <img src="/images/emoji/smile.svg" alt="Accept" style={{ height: '16px', verticalAlign: 'top' }} /></>}
          enableDeclineButton
          declineButtonText={<>{ strings.cookie_banner.decline } <img src="/images/emoji/crying.svg" alt="Decline" style={{ height: '16px', verticalAlign: 'top' }} /></>}

          cookieName={COOKIE_CONSENT_KEY}
          setDeclineCookie={false}
          onAccept={() => setCookieConsent(true)}

          disableStyles
          ButtonComponent={props => <Button sm {...props} />}
          flipButtons
          style={{
            position: 'fixed',
            display: 'block',
            width: '100%',
            bottom: '0px',
            left: '0px',
            padding: '16px 16px 16px 24px',
            lineHeight: '40px',
            color: '#FFFFFF',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            zIndex: 99999
          }}
          contentStyle={{
            float: 'left'
          }}
          buttonClasses=""
          buttonStyle={{
            marginLeft: '16px',
            float: 'right'
          }}
          declineButtonClasses="transparent"
          declineButtonStyle={{
            float: 'right',
            color: '#FFFFFF',
            opacity: 0.8
          }}
        >
          <img src="/images/cookie.png" alt="Cookie consent"
            className="mobile-hide"
            style={{ display: 'inline-block', height: '28px', margin: '0 16px 2px 0', verticalAlign: 'middle' }} />
          { strings.cookie_banner.text }
        </CookieConsent> */}

      </>
  );
}