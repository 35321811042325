import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

// COMPONENT
import Icon from 'components/Icon';

// IMAGES
// import logo from 'images/logo.png';
import logo_sprachelesenlernen from 'images/insl-ev.png';
import logo_stadtsteinbach from 'images/stadt-steinbach.jpg';
// import social_instagram from 'images/social-icons/instagram.svg';
// import social_facebook from 'images/social-icons/facebook.svg';
// import social_youtube from 'images/social-icons/youtube.svg';

// STYLES
import './styles.scss';
import moment from 'moment';

export default function Footer() {
  return (<>
    <Grid container id="partner" justifyContent="center">
      <Grid item xs={12} className="text-center pt-4 pr-4 pb-4 pl-4">
        <b className="display-block text-primary" style={{ textTransform: 'uppercase' }}>Kooperationspartner</b>
        <div className="bg-gray" style={{ width: '128px', height: '1px', margin: '16px auto' }} />

        {/* <Link to="/" className="footer-logo"><img src={logo} alt="Profectus" /></Link> */}

        <a href="https://www.sprachelesenlernen.de/" target="_blank" rel="noopener noreferrer" style={{ width: '90px' }}>
          <img src={logo_sprachelesenlernen} alt="InSL e.V." />
        </a>
        <a href="https://www.stadt-steinbach.de/" target="_blank" rel="noopener noreferrer" style={{ width: '200px' }}>
          <img src={logo_stadtsteinbach} alt="Stadt Steinbach" />
        </a>
      </Grid>
    </Grid>
    <Grid container id="footer" justifyContent="center">
      {/* <Row className="footer-row">
        <Col xs={12} md={4} className="footer-cell">
          <ul>
            <li>Home</li>
            <li>Unsere Arbeit</li>
            <li>Über Uns</li>
            <li>Kontakt</li>
          </ul>
        </Col>
        <Col xs={12} md={4} className="footer-cell">
          <ul>
            <li>Impressum</li>
            <li>Datenschutzerklärung</li>
          </ul>
        </Col>
        <Col xs={12} md={4} className="footer-cell">
          <p>
            <b>MCUT-Productions</b><br />
            Phantasiestraße 7<br />
            77777 Phantasieort
          </p>
        </Col>
      </Row> */}
      <Grid item xs={12} className="footer-content">

        {/* <span className="footer-divider footer-divider-top" /> */}

        Profectus Sprache und Bildung gUG
        {/* <br />Vertreten durch: Semra Gruler-Tatli */}
        <br />
        Daimlerstraße 6<br />
        61449 Steinbach (Taunus)<br />
        <br />
        <div>
          <span style={{ marginLeft: '5px', verticalAlign: 'middle' }}>Do. + Fr. 9:00 - 11:30 Uhr</span><br />
          <Icon size={16} mr={5}>phone</Icon>
          <Link to="tel:+4961719518040" target="_blank" rel="noopener noreferrer">+49 6171 9518040</Link>
        </div>
        <br />
        <div><Icon size={16} mr={5}>smartphone</Icon><Link to="tel:+4915151956498" target="_blank" rel="noopener noreferrer">+49 151 51956498</Link></div>
        <div className="mt-1"><Icon size={16} mr={5}>mail</Icon><Link to="mailto:kontakt@profectus-bildung.de" target="_blank" rel="noopener noreferrer">kontakt@profectus-bildung.de</Link></div>

        <span className="footer-divider footer-divider-bottom" />

        {/* <Link className="footer-social-icon" to="https://www.instagram.com/leon.mrks/" target="_blank" rel="noopener noreferrer">
          <img src={social_instagram} alt="Instagram" />
        </Link>
        <Link className="footer-social-icon" to="https://www.facebook.com/leon.maurice.markus" target="_blank" rel="noopener noreferrer">
          <img src={social_facebook} alt="Facebook" />
        </Link>
        <Link className="footer-social-icon" to="https://www.youtube.com/channel/UCTwSdP-oZDGNrG-QweHT0yA" target="_blank" rel="noopener noreferrer">
          <img src="{social_youtube} alt="YouTube" />
        </Link>

        <span className="footer-divider" /> */}

        <Link to="/impressum">IMPRESSUM</Link> | <Link to="/impressum#datenschutzerklaerung">DATENSCHUTZERKLÄRUNG</Link>
      </Grid>
      <Grid item xs={12} className="footer-copyright">
        COPYRIGHT &copy; { moment().format('YYYY') }
      </Grid>
    </Grid>
  </>);
}