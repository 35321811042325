import React from 'react';
import feather from 'feather-icons';

/**
 * ICONSET: https://feathericons.com/
 */
export default function Icon({ children: icon, size = 24, mt, mr, mb, ml }) {
  const styles = {
    display: 'inline-block',
    width: `${size}px`,
    height: `${size}px`,
    marginTop: mt ? `${mt}px` : undefined,
    marginRight: mr ? `${mr}px` : undefined,
    marginBottom: mb ? `${mb}px` : undefined,
    marginLeft: ml ? `${ml}px` : undefined,
    verticalAlign: 'middle'
  };

  const options = {
    width: `${size}px`,
    height: `${size}px`
  };

  return (
    <div style={styles} dangerouslySetInnerHTML={ { __html: feather.icons[icon].toSvg(options) }} />
  );
}