import React from 'react'

// ICONS
import InfoIcon from './icons/InfoIcon';
import SuccessIcon from './icons/SuccessIcon';
import ErrorIcon from './icons/ErrorIcon';
import CloseIcon from './icons/CloseIcon';

// STYLES
import './styles.scss';

const AlertTemplate = ({ message, options, style, close }) => {
  return (
    <div className="alert" style={style}>
      {options.type === 'info' && <InfoIcon />}
      {options.type === 'success' && <SuccessIcon />}
      {options.type === 'error' && <ErrorIcon />}
      <span style={{ flex: 2 }}>{message}</span>
      <button className="alert-button" onClick={close}>
        <CloseIcon />
      </button>
    </div>
  )
};

export default AlertTemplate;